<div
  class="flex flex-col items-center justify-start min-h-screen w-full"
>
  <div class="flex justify-center my-12">
    <img src="/brand/logo.svg" alt="Bakney" class="w-36" />
  </div>
  <img
    src="/img/BAKNEY.png"
    alt="Bakney"
    class="min-w-[20rem] max-w-[25rem] w-full rounded-xl shadow-lg"
  />
  <div class="flex flex-col items-center justify-center my-12 mx-6">
    <h1 class="text-4xl font-bold">Cosa succederà ora?</h1>
    <p class="text-lg text-gray-700 mt-8 max-w-xl">
      Dal <b>1 dicembre 2024</b> il servizio di <b>Associami</b> sarà integrato
      con <b>Bakney</b>.<br />
      Tutti i tuoi dati saranno automaticamente trasferiti su Bakney, e potrai beneficiare
      del nuovo gestionale <b>senza costi aggiuntivi</b> fino al <b>termine del tuo piano di abbonamento</b>,
      se il tuo piano è già scaduto invece avrai <b>30 giorni di prova gratuita</b>.
      <br />
      <br />
      Al termine del tuo abbonamento o della tua prova gratuita ti è riservato uno
      sconto speciale di <b>100€</b> se rinnovi col piano annuale.
      <br />
      <br />
        Il <b>15 novembre</b> riceverai un'email riepilogativa, e nei giorni successivi maggiori dettagli.
      <br>
      <br>
      Se hai domande o bisogno di assistenza, non esitare a contattarci a
      <a href="mailto:support@bakney.com" class="text-blue-500 underline"
        >support@bakney.com</a
      >
      oppure scrivici su whatsapp al numero <b>+39 3792165951</b>.
    </p>
  </div>

  <footer
    class="bg-gray-100 text-black py-16 rounded-t-3xl w-full outline outline-t outline-1 outline-gray-200"
  >
    <div class="container mx-auto px-4">
      <!-- Main Footer Content -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Brand Section -->
        <div class="space-y-4">
          <img src="/brand/logo.svg" alt="Bakney" class="h-8" />
          <div class="text-sm font-bold">Leveraging tech like never before.</div>

          <div class="text-xs space-y-1">
            <p>Bakney srl</p>
            <p>P. IVA: 04521780236</p>
            <p>
              Sede Legale: C/re Aldo Moro 49, 37032, Monteforte d'Alpone (VR)
            </p>
            <p>N. REA: VR-456410 · Registro delle Imprese di Verona</p>
            <p>Capitale Sociale: € 1.000,00 i.v.</p>
          </div>
        </div>

        <!-- Resources Section -->
        <div class="md:ml-8">
          <h3 class="text-xl font-semibold mb-4">RISORSE</h3>
          <ul class="space-y-2">
            <li>
              <a href="https://bakney.com/prezzi" class="hover:underline"
                >Prezzi</a
              >
            </li>
            <li><a href="https://www.iubenda.com/privacy-policy/19465245" class="hover:underline">Privacy Policy</a></li>
            <li>
              <a href="https://www.iubenda.com/termini-e-condizioni/19465245" class="hover:underline">Termini e Condizioni</a>
            </li>
          </ul>
        </div>

        <!-- Contacts Section -->
        <div>
          <h3 class="text-xl font-semibold mb-4">CONTATTI</h3>
          <ul class="space-y-2">
            <li>
              <a href="mailto:support@bakney.com" class="hover:underline"
                >support@bakney.com</a
              >
            </li>
            <li>
              <p class="text-xs">whatsapp</p>
              <a href="tel:3792165951" class="hover:underline">+39 379 2165951</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Copyright -->
      <div class="mt-8 text-center text-sm">
        <p>
          Copyright © {new Date().getFullYear()} - Tutti i diritti riservati a Bakney srl -
          <a href="https://bakney.com" class="hover:underline"
            >bakney.com</a
          >
        </p>
      </div>
    </div>
  </footer>
</div>
